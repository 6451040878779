var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "homepage_recommendation"
  }, [_c('h2', {
    staticClass: "font-weight-bold mt-1 mb-5"
  }, [_vm._v("Content Recomendation")]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('div', [_c('router-link', {
    attrs: {
      "to": {
        path: ("/content-recommedation/detail/" + (_vm.categoryBook.book))
      }
    }
  }, [_c('button', {
    staticClass: "btn btn-primary"
  }, [_vm._v(" Detail ")])])], 1)])])]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(1), _c('div', [_c('router-link', {
    attrs: {
      "to": {
        path: ("/content-recommedation/detail/" + (_vm.categoryBook.majalah))
      }
    }
  }, [_c('button', {
    staticClass: "btn btn-primary"
  }, [_vm._v(" Detail ")])])], 1)])])]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(2), _c('div', [_c('router-link', {
    attrs: {
      "to": {
        path: ("/content-recommedation/detail/" + (_vm.categoryBook.fiksi))
      }
    }
  }, [_c('button', {
    staticClass: "btn btn-primary"
  }, [_vm._v(" Detail ")])])], 1)])])]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(3), _c('div', [_c('router-link', {
    attrs: {
      "to": {
        path: ("/content-recommedation/detail/" + (_vm.categoryBook.pilihan_editor))
      }
    }
  }, [_c('button', {
    staticClass: "btn btn-primary"
  }, [_vm._v(" Detail ")])])], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', [_vm._v("Buku Populer")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', [_vm._v("Majalah Populer")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', [_vm._v("Fiksi yang sering di baca")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', [_vm._v("Pilihan Editor")])]);
}]

export { render, staticRenderFns }