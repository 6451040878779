<template>
    <div class="homepage_recommendation">
      <h2 class="font-weight-bold mt-1 mb-5">Content Recomendation</h2>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <span>Buku Populer</span>
            </div>
            <div>
              <router-link :to="{ path: `/content-recommedation/detail/${categoryBook.book}`,}">
                <button class="btn btn-primary">
                  Detail
                </button>
              </router-link>   
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <span>Majalah Populer</span>
            </div>
            <div>
              <router-link :to="{ path: `/content-recommedation/detail/${categoryBook.majalah}`,}">
                <button class="btn btn-primary">
                  Detail
                </button>
              </router-link>   
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <span>Fiksi yang sering di baca</span>
            </div>
            <div>
              <router-link :to="{ path: `/content-recommedation/detail/${categoryBook.fiksi}`,}">
                <button class="btn btn-primary">
                  Detail
                </button>
              </router-link>   
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <span>Pilihan Editor</span>
            </div>
            <div>
              <router-link :to="{ path: `/content-recommedation/detail/${categoryBook.pilihan_editor}`,}">
                <button class="btn btn-primary">
                  Detail
                </button>
              </router-link>   
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'recommendationhomepage',
    data() {
      return {
        categoryBook: {
          book: 106,
          majalah: 107,
          fiksi: 103,
          pilihan_editor: 113,
        },
      };
    },
    created() {
    },
  };
  </script>
  <style>
.homepage_recommendation {
  background: #fff;
  padding: 25px;
  margin-top: 25px;
  height: 700px;
  display: bloc;
  border-radius: 5px;
}
</style>